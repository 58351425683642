// Base
@import 'tools/mixins';
@import 'tools/variables';
@import 'tools/breakpoints';
@import 'tools/helper-classes';
@import 'tools/animations';

// Layout
@import 'base/layout';

// Navigation
@import 'components/navigation/main-navigation';
@import 'components/navigation/social-navigation';
@import 'components/navigation/system-navigation';

// Start Graphics
@import 'components/header';
@import 'components/start-graphic';
@import 'components/logo';

// Typography
@import 'type/font';
@import 'type/typography';
@import 'type/links';
@import 'type/text-shadow';


@include bp-to(md){
	.space-before,
	.home .space {
		animation: none;
    	height: 100%;
		transform: none;
		width: 100vw;
	}

	.menu a {
    	font-size: 17vw;
	}
}
