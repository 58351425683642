@mixin nav-link {
	color: $obsidian4;
	text-decoration: none;

	&:hover,
	&:focus {
		color: $obsidian;
	}
}

@mixin copy-link {
	color: $turquoise-medium;

	&:hover,
	&:focus {
		color: $turquoise-dark;
	}
}

main a {
	@include copy-link;
}

nav a,
main nav a {
	@include nav-link;
}

nav.social-navigation {
	a {
		&:hover,
		&:focus {
			color: $quartz;
		}
	}
}
