/*
** breakpoints
*/

@mixin bp-from($point) {
	@if $point == xxl {
		@media (min-width: $screen-xxl-min) { @content ; }
	}
	@if $point == xl {
		@media (min-width: $screen-xl-min) { @content ; }
	}
	@else if $point == lg {
		@media (min-width: $screen-lg-min) { @content ; }
	}
	@else if $point == md {
		@media (min-width: $screen-md-min) { @content ; }
	}
	@else if $point == sm {
		@media (min-width: $screen-sm-min) { @content ; }
	}
	@else if $point == s {
		@media (min-width: $screen-s-min) { @content ; }
	}
	@else if $point == xs {
		@media (min-width: $screen-xs-min)  { @content ; }
	}
	@else if $point == xxs {
		@media (min-width: $screen-xxs-min)  { @content ; }
	}
}

@mixin bp-to($point) {
	@if $point == xxl {
		@media (max-width: $screen-xl-max) { @content ; }
	}
	@if $point == xl {
		@media (max-width: $screen-lg-max) { @content ; }
	}
	@else if $point == lg {
		@media (max-width: $screen-md-max) { @content ; }
	}
	@else if $point == md {
		@media (max-width: $screen-sm-max) { @content ; }
	}
	@else if $point == sm {
		@media (max-width: $screen-s-max) { @content ; }
	}
	@else if $point == s {
		@media (max-width: $screen-xs-max) { @content ; }
	}
	@else if $point == xs {
		@media (max-width: $screen-xxs-max)  { @content ; }
	}
	@else if $point == xxs {
		@media (max-width: $screen-xxxs-max)  { @content ; }
	}
}

@mixin bp-from-to($point) {
	@if $point == xxs-xs {
		@media (min-width: $screen-xxs-min) and (max-width: $screen-xxs-max)  { @content ; }
	}
	@else if $point == xxs-s {
		@media (min-width: $screen-xxs-min) and (max-width: $screen-xs-max)  { @content ; }
	}
	@else if $point == xxs-sm {
		@media (min-width: $screen-xxs-min) and (max-width: $screen-s-max)  { @content ; }
	}
	@else if $point == xxs-md {
		@media (min-width: $screen-xxs-min) and (max-width: $screen-sm-max)  { @content ; }
	}
	@else if $point == xxs-lg {
		@media (min-width: $screen-xxs-min) and (max-width: $screen-md-max)  { @content ; }
	}
	@else if $point == xxs-xl {
		@media (min-width: $screen-xxs-min) and (max-width: $screen-lg-max)  { @content ; }
	}
	@else if $point == xxs-xxl {
		@media (min-width: $screen-xxs-min) and (max-width: $screen-xl-max)  { @content ; }
	}

	@else if $point == xs-s {
		@media (min-width: $screen-xs-min) and (max-width: $screen-xs-max)  { @content ; }
	}
	@else if $point == xs-sm {
		@media (min-width: $screen-xs-min) and (max-width: $screen-s-max)  { @content ; }
	}
	@else if $point == xs-md {
		@media (min-width: $screen-xs-min) and (max-width: $screen-sm-max)  { @content ; }
	}
	@else if $point == xs-lg {
		@media (min-width: $screen-xs-min) and (max-width: $screen-md-max)  { @content ; }
	}
	@else if $point == xs-xl {
		@media (min-width: $screen-xs-min) and (max-width: $screen-lg-max)  { @content ; }
	}
	@else if $point == xs-xxl {
		@media (min-width: $screen-xs-min) and (max-width: $screen-xl-max)  { @content ; }
	}

	@else if $point == s-sm {
		@media (min-width: $screen-s-min) and (max-width: $screen-s-max)  { @content ; }
	}
	@else if $point == s-md {
		@media (min-width: $screen-s-min) and (max-width: $screen-sm-max)  { @content ; }
	}
	@else if $point == s-lg {
		@media (min-width: $screen-s-min) and (max-width: $screen-md-max)  { @content ; }
	}
	@else if $point == s-xl {
		@media (min-width: $screen-s-min) and (max-width: $screen-lg-max)  { @content ; }
	}
	@else if $point == s-xxl {
		@media (min-width: $screen-s-min) and (max-width: $screen-xl-max)  { @content ; }
	}

	@else if $point == sm-md {
		@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max)  { @content ; }
	}
	@else if $point == sm-lg {
		@media (min-width: $screen-sm-min) and (max-width: $screen-md-max)  { @content ; }
	}
	@else if $point == sm-xl {
		@media (min-width: $screen-s-min) and (max-width: $screen-lg-max)  { @content ; }
	}
	@else if $point == sm-xxl {
		@media (min-width: $screen-s-min) and (max-width: $screen-xl-max)  { @content ; }
	}

	@else if $point == md-lg {
		@media (min-width: $screen-md-min) and (max-width: $screen-md-max)  { @content ; }
	}
	@else if $point == md-xl {
		@media (min-width: $screen-md-min) and (max-width: $screen-lg-max)  { @content ; }
	}
	@else if $point == md-xxl {
		@media (min-width: $screen-md-min) and (max-width: $screen-xl-max)  { @content ; }
	}

	@else if $point == lg-xl {
		@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max)  { @content ; }
	}
	@else if $point == lg-xxl {
		@media (min-width: $screen-lg-min) and (max-width: $screen-xl-max)  { @content ; }
	}

	@else if $point == xl-xxl {
		@media (min-width: $screen-xl-min) and (max-width: $screen-xl-max)  { @content ; }
	}
}

/*
** make breakpoints available for js
*/

body:before {
  content: "xxs";
  display: inline-block;
  position: absolute;
  text-indent: -9999px;

  @include bp-from(xs) {
	  content: "xs";
  }

  @include bp-from(s) {
	  content: "s";
  }

  @include bp-from(sm) {
	  content: "sm";
  }

  @include bp-from(md) {
	  content: "md";
  }

  @include bp-from(lg) {
	  content: "lg";
  }

  @include bp-from(xl) {
	  content: "xl";
  }

  @include bp-from(xxl) {
	  content: "xxl";
  }
}
