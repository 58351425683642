/*
** base
*/

body {
	color: #333;
	font-family: 'Roboto', sans-serif;
	line-height: 1.45;

	@include bp-to(s) {
		font-size: 16px;
	}

	@include bp-from(s) {
		font-size: 18px;
	}

	@include bp-from(md) {
		font-size: 20px;
	}

	@include bp-from(lg) {
		font-size: 24px;
	}

	@include bp-from(xl) {
		font-size: 28px;
	}

	@include bp-from(xxl) {
		font-size: 32px;
	}
}


/*
** copy
*/

p {
	margin: 0 0 $unit * 0.5;

	@include bp-from(lg) {
		margin: 0 0 $unit;
	}

	@include bp-from(xl) {
		margin: 0 0 $unit * 1.5;
	}
}

small {
	display: inline-block;
}

small, .font-small {
	@include bp-to(lg) {
		font-size: 14px;
	}

	@include bp-from(lg) {
		font-size: 18px;
	}
}

aside {
	color: #879080;
	font-family: 'robotoitalic';
}

.entry-text {
	ul {
		margin-left: 23px;
	}

	blockquote {
		color: inherit;
		font-size: inherit;
    	line-height: inherit;
		border-left: 2px solid #222;
	    padding-left: 15px;
	    margin: 30px 15px;

		p {
			margin-bottom: 0;
		}
	}

	figcaption {
		font-size: 14px;
	}

	figure {
		margin-bottom: 30px;
	}
}

@include bp-from(sm) {
	.col-count-2 {
		column-count: 2;
		column-gap: 60px;
    }
}

/*
** other
*/

figure {
	margin: 0;
}

/*
** icons
*/

.icon {
	display: inline-block;
	fill: currentColor;
	height: 1rem;
	width: 1rem;
}

/*
** start page
*/

.site-title { //h2
	letter-spacing: 0.08em;
	margin: 0;

	@include bp-to(sm) {
		font-size: 1.5em;
	}

	@include bp-from(sm) {
		font-size: 2em;
	}
}
