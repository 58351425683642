/*
** webfonts
*/

/* Roboto */

// bold
@font-face {
    font-family: 'robotobold';
    src: url('../font/roboto/roboto-bold-webfont.woff2') format('woff2'),
         url('../font/roboto/roboto-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotobold_italic';
    src: url('../font/roboto/roboto-bolditalic-webfont.woff2') format('woff2'),
         url('../font/roboto/roboto-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// medium
@font-face {
    font-family: 'robotomedium';
    src: url('../font/roboto/roboto-medium-webfont.woff2') format('woff2'),
         url('../font/roboto/roboto-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotomedium_italic';
    src: url('../font/roboto/roboto-mediumitalic-webfont.woff2') format('woff2'),
         url('../font/roboto/roboto-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// regular
@font-face {
    font-family: 'robotoregular';
    src: url('../font/roboto/roboto-regular-webfont.woff2') format('woff2'),
         url('../font/roboto/roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotoitalic';
    src: url('../font/roboto/roboto-italic-webfont.woff2') format('woff2'),
         url('../font/roboto/roboto-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// light
@font-face {
    font-family: 'robotolight';
    src: url('../font/roboto/roboto-light-webfont.woff2') format('woff2'),
         url('../font/roboto/roboto-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotolight_italic';
    src: url('../font/roboto/roboto-lightitalic-webfont.woff2') format('woff2'),
         url('../font/roboto/roboto-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// thin
@font-face {
    font-family: 'robotothin';
    src: url('../font/roboto/roboto-thin-webfont.woff2') format('woff2'),
         url('../font/roboto/roboto-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotothin_italic';
    src: url('../font/roboto/roboto-thinitalic-webfont.woff2') format('woff2'),
         url('../font/roboto/roboto-thinitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


/* Stonestick */

@font-face {
    font-family: 'stonestick';
    src: url('../font/stonestick/stonestick.woff2') format('woff2'),
    	 url('../font/stonestick/stonestick.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* Barlow */

@font-face {
    font-family: 'barlowregular';
    src: url('../font/barlow/barlow-regular-webfont.woff2') format('woff2'),
         url('../font/barlow/barlow-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'barlowbold';
    src: url('../font/barlow/barlow-bold-webfont.woff2') format('woff2'),
         url('../font/barlow/barlow-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'barlowextrabold';
    src: url('../font/barlow/barlow-extrabold-webfont.woff2') format('woff2'),
         url('../font/barlow/barlow-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'barlowblack';
    src: url('../font/barlow/barlow-black-webfont.woff2') format('woff2'),
         url('../font/barlow/barlow-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
