/* text-shadow keyframes
-------------------------------------------------------------------------------- */

@keyframes text-shadow-once {
	0% {
		transform: translate3d(0, 0, 0);
		text-shadow: 0 0 0 rgba($purple-medium, 0.75),
					0 0 0 rgba($turquoise-dark, 0.75),
					0 0 0 rgba($turquoise-grey-light, 0.9);
	}
	35% {
		transform: translate3d(1px, 0, 0);
		text-shadow: 5px -6px 0 rgba($purple-medium, 0.75),
					0 0 0 rgba($turquoise-dark, 0.75),
					0 0 0 rgba($turquoise-grey-light, 0.9);
	}
	50% {
		transform: translate3d(3px, 1px, 0);
		text-shadow: 5px -6px 0 rgba($purple-medium, 0.75),
					0 6px 0 rgba($turquoise-dark, 0.75),
					0 0 0 rgba($turquoise-grey-light, 0.9);
	}
	70% {
		transform: translate3d(1px, 3px, 0);
		text-shadow: 5px -6px 0 rgba($purple-medium, 0.75),
					0 6px 0 rgba($turquoise-dark, 0.75),
					-6px -4px 0 rgba($turquoise-grey-light, 0.9);
	}
	100% {
		transform: translate3d(0, 1px, 0);
		text-shadow: 5px -6px 0 rgba($purple-medium, 0.75),
					0 6px 0 rgba($turquoise-dark, 0.75),
					-6px -4px 0 rgba($turquoise-grey-light, 0.9);
	}
}

@keyframes text-shadow-once-night {
	0% {
		transform: translate3d(0, 0, 0);
		text-shadow: 0 0 0 rgba($night-blue, 0.75),
					0 0 0 rgba($night-purple, 0.75),
					0 0 0 rgba($night-red, 0.9);
	}
	35% {
		transform: translate3d(1px, 0, 0);
		text-shadow: 5px -6px 0 rgba($night-blue, 0.75),
					0 0 0 rgba($night-purple, 0.75),
					0 0 0 rgba($night-red, 0.9);
	}
	50% {
		transform: translate3d(3px, 1px, 0);
		text-shadow: 5px -6px 0 rgba($night-blue, 0.75),
					0 6px 0 rgba($night-purple, 0.75),
					0 0 0 rgba($night-red, 0.9);
	}
	70% {
		transform: translate3d(1px, 3px, 0);
		text-shadow: 5px -6px 0 rgba($night-blue, 0.75),
					0 6px 0 rgba($night-purple, 0.75),
					-6px -4px 0 rgba($night-red, 0.9);
	}
	100% {
		transform: translate3d(0, 1px, 0);
		text-shadow: 5px -6px 0 rgba($night-blue, 0.75),
					0 6px 0 rgba($night-purple, 0.75),
					-6px -4px 0 rgba($night-red, 0.9);
	}
}

/*
@keyframes text-shadow-infinite {
	0% {
		transform: translate3d(0, 0, 0);
		text-shadow: 0 0 0 rgba($purple-medium, 0.75),
					0 0 0 rgba($turquoise-dark, 0.75),
					0 0 0 rgba($turquoise-grey-light, 0.9);
	}
	10% {
		transform: translate3d(1px, 0, 0);
		text-shadow: 5px -6px 0 rgba($purple-medium, 0.75),
					0 0 0 rgba($turquoise-dark, 0.75),
					0 0 0 rgba($turquoise-grey-light, 0.9);
	}
	30% {
		transform: translate3d(3px, 1px, 0);
		text-shadow: 5px -2px 0 rgba($purple-medium, 0.75),
					0 6px 0 rgba($turquoise-dark, 0.75),
					0 0 0 rgba($turquoise-grey-light, 0.9);
	}
	50% {
		transform: translate3d(1px, 3px, 0);
		text-shadow: 0 0 0 rgba($purple-medium, 0.75),
					-5px 2px 0 rgba($turquoise-dark, 0.75),
					-6px -4px 0 rgba($turquoise-grey-light, 0.9);
	}
	70% {
		transform: translate3d(0, 1px, 0);
		text-shadow: 0 0 0 rgba($purple-medium, 0.75),
					0 0 0 rgba($turquoise-dark, 0.75),
					-2px -6px 0 rgba($turquoise-grey-light, 0.9);
	}
	90% {
		transform: translate3d(0, 0, 0);
		text-shadow: 0 0 0 rgba($purple-medium, 0.75),
					0 0 0 rgba($turquoise-dark, 0.75),
					0 0 0 rgba($turquoise-grey-light, 0.9);
	}
	100% {
		transform: translate3d(0, 0, 0);
		text-shadow: 0 0 0 rgba($purple-medium, 0.75),
					0 0 0 rgba($turquoise-dark, 0.75),
					0 0 0 rgba($turquoise-grey-light, 0.9);
	}
}
*/

.menu {
	a {
		transition: color 0.15s ease-in;
		will-change: transform;

		&:hover,
		&:focus {
			color: $obsidian;
			animation: text-shadow-once 0.35s ease normal forwards;

			.nighttime & {
				animation: text-shadow-once-night 0.35s ease normal forwards;
			}
		}
	}
}

/* box-shadow keyframe
-------------------------------------------------------------------------------- */

@keyframes box-shadow-once {
	0% {
		transform: translate3d(0, 0, 0);
		box-shadow: 0 0 0 rgba($purple-medium, 0.75),
					0 0 0 rgba($turquoise-dark, 0.75),
					0 0 0 rgba($turquoise-grey-light, 0.9);
	}
	35% {
		transform: translate3d(1px, 0, 0);
		box-shadow: 4px -5px 0 rgba($purple-medium, 0.75),
					0 0 0 rgba($turquoise-dark, 0.75),
					0 0 0 rgba($turquoise-grey-light, 0.9);
	}
	50% {
		transform: translate3d(2px, 1px, 0);
		box-shadow: 4px -5px 0 rgba($purple-medium, 0.75),
					0 5px 0 rgba($turquoise-dark, 0.75),
					0 0 0 rgba($turquoise-grey-light, 0.9);
	}
	70% {
		transform: translate3d(1px, 2px, 0);
		box-shadow: 4px -5px 0 rgba($purple-medium, 0.75),
					0 5px 0 rgba($turquoise-dark, 0.75),
					-5px -4px 0 rgba($turquoise-grey-light, 0.9);
	}
	100% {
		transform: translate3d(0, 1px, 0);
		box-shadow: 4px -5px 0 rgba($purple-medium, 0.75),
					0 5px 0 rgba($turquoise-dark, 0.75),
					-5px -4px 0 rgba($turquoise-grey-light, 0.9);
	}
}

@keyframes box-shadow-once-night {
	0% {
		transform: translate3d(0, 0, 0);
		box-shadow: 0 0 0 rgba($night-blue, 0.75),
					0 0 0 rgba($night-purple, 0.75),
					0 0 0 rgba($night-red, 0.9);
	}
	35% {
		transform: translate3d(1px, 0, 0);
		box-shadow: 4px -5px 0 rgba($night-blue, 0.75),
					0 0 0 rgba($night-purple, 0.75),
					0 0 0 rgba($night-red, 0.9);
	}
	50% {
		transform: translate3d(2px, 1px, 0);
		box-shadow: 4px -5px 0 rgba($night-blue, 0.75),
					0 5px 0 rgba($night-purple, 0.75),
					0 0 0 rgba($night-red, 0.9);
	}
	70% {
		transform: translate3d(1px, 2px, 0);
		box-shadow: 4px -5px 0 rgba($night-blue, 0.75),
					0 5px 0 rgba($night-purple, 0.75),
					-5px -4px 0 rgba($night-red, 0.9);
	}
	100% {
		transform: translate3d(0, 1px, 0);
		box-shadow: 4px -5px 0 rgba($night-blue, 0.75),
					0 5px 0 rgba($night-purple, 0.75),
					-5px -4px 0 rgba($night-red, 0.9);
	}
}

.social-navigation {
	a {
		transition: background-color 0.15s ease-in;
		will-change: transform;

		&:hover,
		&:focus {
			background-color: $obsidian;
			animation: box-shadow-once 0.35s ease normal forwards;

			.nighttime & {
				animation: box-shadow-once-night 0.35s ease normal forwards;
			}
		}
	}
}

/* static responsive text-shadow
-------------------------------------------------------------------------------- */

/*
@mixin responsive-static-text-shadow {
	@include bp-to(s) {
		text-shadow: 2px -3px 0 rgba($purple-medium, 0.55),
					 0 3px 0 rgba($turquoise-dark, 0.55),
					 -3px -2px 0 rgba($turquoise-grey-light, 0.7);
	}

	@include bp-from-to(s-md) {
		text-shadow: 3px -4px 0 rgba($purple-medium, 0.55),
					 0 4px 0 rgba($turquoise-dark, 0.55),
					 -4px -2px 0 rgba($turquoise-grey-light, 0.7);
	}

	@include bp-from-to(md-lg) {
		text-shadow: 4px -5px 0 rgba($purple-medium, 0.55),
					 0 5px 0 rgba($turquoise-dark, 0.55),
					 -5px -3px 0 rgba($turquoise-grey-light, 0.7);
	}

	@include bp-from(lg) {
		text-shadow: 5px -6px 0 rgba($purple-medium, 0.55),
					 0 6px 0 rgba($turquoise-dark, 0.55),
					 -6px -4px 0 rgba($turquoise-grey-light, 0.7);
	}
}
*/

/* static small text-shadow
-------------------------------------------------------------------------------- */

.breadcrumb-item,
.system-navigation a {
	transition: text-shadow 0.15s ease-in, color 0.15s ease-in;

	&:hover,
	&:focus {
		color: $obsidian;
		text-shadow: 1px -1px 0 rgba($purple-medium, 0.75),
					 -1px -1px 0 rgba($turquoise-grey-light, 0.9),
					 0 1px 0 rgba($turquoise-dark, 0.75);

		.nighttime & {
			text-shadow: 1px -1px 0 rgba($night-blue, 0.75),
						 -1px -1px 0 rgba($night-red, 0.9),
						 0 1px 0 rgba($night-purple, 0.75);
		}
	}
}
