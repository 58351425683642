/* base
-------------------------------------------------------------------------------- */

html {
	height: 100%
}

html,
body {
	min-height: 100%;
	min-width: 320px;
}

body {
    background-color: $quartz-light;
}

.page {
	.home &,
	&.payload-gallery,
	&.payload-category {
		@include bp-from(md) {
			display: flex;
			flex-direction: row;
		}
	}
}

.content-container {
	flex-direction: column;
}

/* columns
-------------------------------------------------------------------------------- */

.left,
.right {
	width: 100vw;

	@include bp-from(md) {
		display: flex;
			align-items: flex-start;
			flex-direction: column;
			justify-content: center;
		height: 100vh;
		width: 50vw;
	}
}

.left {
	@include bp-from(md) {
		order: 1;
	}
}

.right {
	@include bp-from(md) {
		order: 2;
	}

	.home & {
		position: relative;

		@include bp-to(s) {
			height: 300px;
		}

		@include bp-from-to(s-sm) {
			height: 400px;
		}

		@include bp-from-to(sm-md) {
			height: 500px;
		}

		@include bp-from(md) {
			height: 100vh;
		}
	}

	.archive &,
	.single & {
		justify-content: flex-start;
		overflow-x: hidden;
    	overflow-y: auto;
	}
}

.right-big {
	@include bp-from(md) {
		width: 60vw;
	}
}

.left-small {
	@include bp-from(md) {
		width: 40vw;
	}
}

.grid {
	height: 100vh;
	width: 100%;
}

.container {
	margin-right: auto;
	margin-left: auto;
    padding-left: $unit * 0.5;
    padding-right: $unit * 0.5;

	@include bp-from(sm) {
		width: 750px;
	}

	@include bp-from(md) {
		width: 970px;
	}

	@include bp-from(lg) {
		width: 1170px;
	}

	@include bp-from(xl) {
		width: 1670px;
	}
}

/* 50% content
-------------------------------------------------------------------------------- */

.content-container {
	.home & {
		width: 50%;
	}
}

.template-notice-wrapper {
	display: block;
	position: relative;
	width: 50%;
}

.template-notice {
	position: absolute;
	top: 0;
	background: #ccc;
	padding: 15px;
}

/* footer
-------------------------------------------------------------------------------- */

.page-footer {
	padding: $unit * 2 0;

	.home & {
		text-align: center;
		width: 100%;

		@include bp-from(md) {
			width: 50vw;
		}
	}

	.home & {
		@include bp-from(s) {
			position: absolute;
				bottom: 0;
				left: 0;
		}
	}

	.left-small & {
		width: 35vw;
	}
}

.page-blogpost,
.post-template-default {
	.page-footer {
		border-top: 1px solid $clay-dark;
	}
}

/* screenreader only
-------------------------------------------------------------------------------- */

.sr-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 0;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 0;
}

.to-xs {
	@include bp-from(xs) {
		display: none;
	}
}

.from-xs {
	display: none;

	@include bp-from(xs) {
		display: inline-block;
	}
}

.to-sm {
	@include bp-from(sm) {
		display: none;
	}
}

.from-sm {
	display: none;

	@include bp-from(sm) {
		display: inline-block;
	}
}

.to-md {
	@include bp-from(md) {
		display: none;
	}
}

.from-md {
	display: none;

	@include bp-from(md) {
		display: inline-block;
	}
}
