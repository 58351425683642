/*
** header
*/

// header images

.header-rectangles-bottom .custom-header-media:before {
	background: url('../images/layout/rectangles-wht.svg') center bottom no-repeat;
}

.header-rectangles-top .custom-header-media:before {
	background: url('../images/layout/rectangles-wht-top-clip.png') center top no-repeat;
}

.header-clear .custom-header-media:before {
	background: none;
}


// site-branding

.has-header-image.twentyseventeen-front-page,
.has-header-video.twentyseventeen-front-page,
.has-header-image.home.blog,
.has-header-video.home.blog {
	.site-branding {
		top: 0;
	}
}


/*
** header text
*/

.site-branding-text {
	margin: 0 auto;
	text-align: center;
	width: 100%;

	h1 {
		font-size: 1.5em;
		margin-bottom: 0;

		@include bp-from(sm) {
		}
	}

}

.site-branding-text,
.site-title a {
	color: $quartz;
}


/* Scroll down arrow */

.header .menu-scroll-down {
	display: block;
	padding: 1em;
	position: absolute;
	right: 0;
}

.header .menu-scroll-down .icon {
	-webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
	-ms-transform: rotate(90deg); /* IE 9 */
	transform: rotate(90deg);
}

.header .menu-scroll-down {
	color: $quartz;
	top: 2em;
}

.header .navigation-top .menu-scroll-down {
	color: #767676;
	top: 0.7em;
}

.menu-scroll-down:focus {
	outline: thin dotted;
}

.menu-scroll-down .icon {
	height: 18px;
	width: 18px;
}



/*
** toDo
*/

@media screen and (min-width: 30em) { //480px
	.page-wrapper .site-branding {
	    padding: 3em 0 8em 0;
	}

	.page-fullscreen {
		.site-branding {
			padding: 3em 0 5em 0;
		}

		.custom-header {
			margin-bottom: 0 !important;
		}
	}
}
