/* Social nav */

.social-navigation {
	margin-bottom: 0;
}

.social-navigation ul {
	display: flex;
	align-items: center;
	justify-content: space-between;
	list-style: none;
	margin: 0 $unit * 0.5;

	@include bp-from(xs) {
		margin: 0 auto;
		width: 300px;
	}
}

.social-navigation li {
	display: inline-block;
}

.social-navigation a {
	background-color: #333;
	-webkit-border-radius: 40px;
	border-radius: 35px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	height: 35px;
	text-align: center;
	width: 35px;

	@include bp-from(s) {
		border-radius: 40px;
		height: 40px;
		width: 40px;
	}
}

.social-links-menu {
	a span {
		display: none;
	}

	.icon {
		height: 16px;
		width: 16px;
	}
}
