.stars,
.moon,
.rocket {
	opacity: 0;

	animation-name: fadeIn;
	animation-duration: 2s;
	animation-fill-mode: forwards;
	animation-delay: 1s;
}

/* startpage space container
---------------------------------------- */

.space-before {
	display: block;
	height: 100vh;
	position: absolute;
	right: 0;
	top: 0;
	transform: translate3d(100%, 0, 0);
	width: 50vw;
	will-change: transform;
	z-index: -1;

	animation-name: slideInRight;
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
	animation-timing-function: ease;

	.daytime & {
		background: #73b1aa;
	}

	.nighttime & {
		background: #1b1c2d;
	}
}

.home .space {
	width: 0%;

	animation-name: grow-right;
	animation-duration: 0.3s;
	animation-fill-mode: forwards;
	animation-delay: 0.2s;
}

/* startpage space graphics
---------------------------------------- */

.space {
	position: absolute;
	bottom: 0;
	right: 0;
	height: 100vh;
	overflow: hidden;
	z-index: -1;

	/* sky
	---------------------------------------- */

	.sky {
		//background: linear-gradient($turquoise-grey-dark 0%, $turquoise-grey-medium 20%, $turquoise-grey-light 100%);
		position: relative;
		height: 100%;
		overflow: hidden;

		.daytime & {
			background: linear-gradient(#2c1b2d 0%, #59415e 20%, $turquoise-grey-light 100%);
		}

		.nighttime & {
			background: linear-gradient(#000000 0, #413f64 50%, #d46b70 90%);
		}
	}

	/* planet
	---------------------------------------- */

	#planet {
		.daytime & {
			g:first-child {
				fill: #32a6a3;
				opacity: 0.9;
			}

			g:nth-child(2) {
				fill: #73748A;
			}

			g:nth-child(3) {
				fill: #59415E;
			}

			g:nth-child(4) {
				fill: #2C1B2D;
			}
		}

		.nighttime & {
			g:first-child {
				fill: #834362;
				opacity: 0.9;
			}

			g:nth-child(2) {
				fill: #42325c;
			}

			g:nth-child(3) {
				fill: #2b2950;
			}

			g:nth-child(4) {
				fill: #1c1b35;
			}
		}
	}
}

body:not(.js-load-animation-locked) {
	.space {
		.mountain-4 {
			animation-name: slideInUpBounce;
			@include animated(0.5s, 0.2s);
		}

		.mountain-3 {
			animation-name: slideInUpBounce;
			@include animated(0.5s, 0.4s);
		}

		.mountain-2 {
			animation-name: slideInUpBounce;
			@include animated(0.5s, 0.55s);
		}

		.mountain-1 {
			animation-name: slideInUpBounce;
			@include animated(0.5s, 0.7s);
		}
	}
}

body.js-load-animation-locked {
	.right {
		.mountain {
			transform: translate3d(0, 0, 0);
			transition: transform 8s ease 0.01s;
		}
	}

	.right:hover {
		.mountain-4 {
			transform: translate3d(-30px, 30px, 0);
			transition: transform 8s ease 0.1s;
		}

		.mountain-3 {
			transform: translate3d(30px, 30px, 0);
			transition: transform 8s ease 0.1s;
		}

		.mountain-2 {
			transform: translate3d(-30px, 50px, 0);
			transition: transform 8s ease 0.1s;
		}

		.mountain-1 {
			transform: translate3d(30px, 50px, 0);
			transition: transform 8s ease 0.1s;
		}
	}
}

/* starfield
---------------------------------------- */

@function makeSomeStars ($n) {
	$value: '#{random(2000)}px #{random(2000)}px #{$quartz}';

	@for $i from 2 through $n {
		$value: '#{$value}, #{random(2000)}px #{random(2000)}px #{$quartz}';
	}
	@return unquote($value)
}

$stars-small: makeSomeStars(700);
$stars-medium: makeSomeStars(200);
$stars-big: makeSomeStars(100);

.stars-1 {
	width: 1px;
	height: 1px;
	background: transparent;
	box-shadow: $stars-small;
	animation: starField 50s linear infinite, flicker 3s 1s linear infinite;

	&:after {
		content: " ";
		position: absolute;
		top: 2000px;
		width: 1px;
		height: 1px;
		background: transparent;
		box-shadow: $stars-small;
	}
}

.stars-2 {
	width: 2px;
	height: 2px;
	background: transparent;
	box-shadow: $stars-medium;
	animation: starField 100s linear infinite, flicker 3s 0.5s linear infinite;

	&:after {
		content: " ";
		position: absolute;
		top: 2000px;
		width: 2px;
		height: 2px;
		background: transparent;
		box-shadow: $stars-medium;
	}
}

.stars-3 {
	width: 3px;
	height: 3px;
	background: transparent;
	box-shadow: $stars-big;
	animation: starField 150s linear infinite, flicker 3s linear infinite;

	&:after {
		content: " ";
		position: absolute;
		top: 2000px;
		width: 3px;
		height: 3px;
		background: transparent;
		box-shadow: $stars-big;
	}
}

@keyframes starField {
 	from { transform: translateY(0px); }
	to { transform: translateY(-2000px); }
}

$outline: rgba($quartz, 0.4);

@keyframes flicker {
	0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% {
		opacity: 0.99;
		text-shadow: -1px -1px 0 $outline,
					 1px -1px 0 $outline,
					 -1px 1px 0 $outline,
					 1px 1px 0 $outline,
					 0 -2px 8px, 0 0 2px,
					 0 0 5px #ff7e00,
					 0 0 15px #ff4444,
					 0 0 2px #ff7e00,
					 0 2px 3px #000;
	}

	20%, 21.999%, 63%, 63.999%, 65%, 69.999% {
		opacity: 0.4;
		text-shadow: none;
	}
}

/* moving sky
---------------------------------------- */

.sky-2 {
	animation-name: northern-light;
		animation-direction: alternate;
		animation-duration: 10s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	height: 200%;
		max-height: 2400px;
	position: absolute;
		left: -50%;
		top: -50%;
	width: 200%;
		max-width: 2400px;
	will-change: transform;

	//background: linear-gradient($turquoise-grey-dark 0%, $turquoise-grey-medium 40%, $turquoise-grey-light 100%);

	.daytime & {
		background: linear-gradient(#2c1b2d 0%, #59415e 30%, #82D3CB 100%);
		mix-blend-mode: hard-light;
		opacity: 1;
	}

	.nighttime & {
		background: linear-gradient($obsidian 0, $night-blue 40%, $night-red 85%);
		mix-blend-mode: soft-light;
		opacity: 0.7;
	}
}

@keyframes northern-light {
	0% { transform: translate(5%, -2%); }
	25% { transform: translate(10%, 7%); }
	40% { transform: rotate(-10deg); }
	60% { transform: translate(7%, -2%); }
	85% { transform: translate(6%, 3%) rotate(12deg); }
	100% { transform: none; }
}

/* crystalline
---------------------------------------- */

@include bp-to(md) {
	#crystalline {
		display: none;
	}
}

@include bp-from(md) {
	#crystalline {
		animation-name: fadeIn;
			animation-delay: 1s;
			animation-duration: 2s;
			animation-fill-mode: forwards;
		background: transparent;
		display: flex;
			align-items: center;
			justify-content: center;
		height: 500px;
		opacity: 0;
		position: absolute;
			bottom: -120px;
		width: 50vw;
	}

	.crystalline-item {
		background: linear-gradient(58deg,
			rgba(43, 79, 84, 0.5) 0%,
			rgba(131, 211, 203, 0.5) 8%,
			rgba(63, 96, 100, 0.5) 50%,
			rgba(255, 255, 255, 0.5) 55%,
			rgba(63, 96, 100, 0.5) 60%,
			rgba(131, 211, 203, 0.5) 92%,
			rgba(43, 79, 84, 0.5) 100%);
		background-size: 100% 200%;
		mix-blend-mode: overlay;
		opacity: 0.35;
		position: absolute;
		will-change: background;

		.nighttime & {
			background: linear-gradient(58deg,
				rgba(0, 0, 0, 0.5) 0%,
				rgba(86, 87, 107, 0.5) 8%,
				rgba(65, 63, 100, 0.5) 50%,
				rgba(212, 107, 112, 0.5) 55%,
				rgba(65, 63, 100, 0.5) 60%,
				rgba(86, 87, 107, 0.5) 92%,
				rgba(255, 255, 255, 0.5) 100%);
			background-size: 100% 200%;
			mix-blend-mode: color-dodge;
			opacity: 0.3;
		}

		&:nth-child(1) {
			width: 952px;
			height: 1183px;
			-webkit-clip-path: polygon(93% 61%, 85% 1%, 89% 8%);
			clip-path: polygon(93% 61%, 85% 1%, 89% 8%);
			//animation: auroraLight 6894ms -6910ms ease-in-out infinite alternate;
		}
		&:nth-child(2) {
			width: 821px;
			height: 1385px;
			-webkit-clip-path: polygon(32% 80%, 66% 99%, 24% 42%);
			clip-path: polygon(32% 80%, 66% 99%, 24% 42%);
			animation: auroraLight 7485ms -703ms ease-in-out infinite alternate;
		}
		&:nth-child(3) {
			width: 817px;
			height: 1184px;
			-webkit-clip-path: polygon(88% 79%, 35% 92%, 40% 73%);
			clip-path: polygon(88% 79%, 35% 92%, 40% 73%);
			//animation: auroraLight 3959ms -8614ms ease-in-out infinite alternate;
		}
		&:nth-child(4) {
			width: 902px;
			height: 1323px;
			-webkit-clip-path: polygon(56% 96%, 12% 35%, 68% 83%);
			clip-path: polygon(56% 96%, 12% 35%, 68% 83%);
			animation: auroraLight 6597ms -6469ms ease-in-out infinite alternate;
		}
		&:nth-child(5) {
			width: 967px;
			height: 1317px;
			-webkit-clip-path: polygon(7% 99%, 35% 46%, 19% 18%);
			clip-path: polygon(7% 99%, 35% 46%, 19% 18%);
			//animation: auroraLight 3799ms -9870ms ease-in-out infinite alternate;
		}
		&:nth-child(6) {
			width: 1073px;
			height: 1297px;
			-webkit-clip-path: polygon(98% 14%, 71% 97%, 51% 77%);
			clip-path: polygon(98% 14%, 71% 97%, 51% 77%);
			animation: auroraLight 3310ms -1180ms ease-in-out infinite alternate;
		}
		&:nth-child(7) {
			width: 882px;
			height: 1198px;
			-webkit-clip-path: polygon(36% 65%, 96% 79%, 16% 11%);
			clip-path: polygon(36% 65%, 96% 79%, 16% 11%);
			//animation: auroraLight 4076ms -962ms ease-in-out infinite alternate;
		}
		&:nth-child(8) {
			width: 871px;
			height: 1318px;
			-webkit-clip-path: polygon(62% 85%, 29% 100%, 65% 86%);
			clip-path: polygon(62% 85%, 29% 100%, 65% 86%);
			animation: auroraLight 7138ms -2304ms ease-in-out infinite alternate;
		}
		&:nth-child(9) {
			width: 1074px;
			height: 1213px;
			-webkit-clip-path: polygon(55% 53%, 91% 47%, 36% 10%);
			clip-path: polygon(55% 53%, 91% 47%, 36% 10%);
			//animation: auroraLight 3782ms -4968ms ease-in-out infinite alternate;
		}
		&:nth-child(10) {
			width: 1027px;
			height: 1018px;
			-webkit-clip-path: polygon(52% 83%, 58% 84%, 49% 17%);
			clip-path: polygon(52% 83%, 58% 84%, 49% 17%);
			animation: auroraLight 6454ms -785ms ease-in-out infinite alternate;
		}
		&:nth-child(11) {
			width: 916px;
			height: 1394px;
			-webkit-clip-path: polygon(23% 36%, 73% 88%, 51% 38%);
			clip-path: polygon(23% 36%, 73% 88%, 51% 38%);
			//animation: auroraLight 7973ms -8878ms ease-in-out infinite alternate;
		}
		&:nth-child(12) {
			width: 816px;
			height: 1132px;
			-webkit-clip-path: polygon(61% 86%, 6% 88%, 43% 33%);
			clip-path: polygon(61% 86%, 6% 88%, 43% 33%);
			animation: auroraLight 3738ms -9194ms ease-in-out infinite alternate;
		}
		&:nth-child(13) {
			width: 1133px;
			height: 1203px;
			-webkit-clip-path: polygon(4% 4%, 95% 18%, 40% 39%);
			clip-path: polygon(4% 4%, 95% 18%, 40% 39%);
			//animation: auroraLight 5667ms -8489ms ease-in-out infinite alternate;
		}
		&:nth-child(14) {
			width: 851px;
			height: 1394px;
			-webkit-clip-path: polygon(48% 92%, 72% 37%, 83% 63%);
			clip-path: polygon(48% 92%, 72% 37%, 83% 63%);
			animation: auroraLight 3192ms -6085ms ease-in-out infinite alternate;
		}
		&:nth-child(15) {
			width: 1296px;
			height: 1250px;
			-webkit-clip-path: polygon(20% 64%, 14% 55%, 29% 48%);
			clip-path: polygon(20% 64%, 14% 55%, 29% 48%);
			//animation: auroraLight 6256ms -4893ms ease-in-out infinite alternate;
		}
		&:nth-child(16) {
			width: 1023px;
			height: 1063px;
			-webkit-clip-path: polygon(80% 50%, 4% 35%, 31% 60%);
			clip-path: polygon(80% 50%, 4% 35%, 31% 60%);
			animation: auroraLight 5679ms -4976ms ease-in-out infinite alternate;
		}
		&:nth-child(17) {
			width: 1172px;
			height: 1000px;
			-webkit-clip-path: polygon(24% 89%, 16% 33%, 72% 47%);
			clip-path: polygon(24% 89%, 16% 33%, 72% 47%);
			//animation: auroraLight 5893ms -202ms ease-in-out infinite alternate;
		}
		&:nth-child(18) {
			width: 840px;
			height: 1323px;
			-webkit-clip-path: polygon(50% 78%, 2% 31%, 5% 46%);
			clip-path: polygon(50% 78%, 2% 31%, 5% 46%);
			animation: auroraLight 5731ms -5060ms ease-in-out infinite alternate;
		}
		&:nth-child(19) {
			width: 1092px;
			height: 980px;
			-webkit-clip-path: polygon(87% 51%, 46% 27%, 84% 68%);
			clip-path: polygon(87% 51%, 46% 27%, 84% 68%);
			//animation: auroraLight 3412ms -2504ms ease-in-out infinite alternate;
		}
		&:nth-child(20) {
			width: 949px;
			height: 1252px;
			-webkit-clip-path: polygon(64% 66%, 37% 92%, 14% 41%);
			clip-path: polygon(64% 66%, 37% 92%, 14% 41%);
			animation: auroraLight 6238ms -1697ms ease-in-out infinite alternate;
		}
		&:nth-child(21) {
			width: 1049px;
			height: 977px;
			-webkit-clip-path: polygon(62% 98%, 56% 29%, 79% 74%);
			clip-path: polygon(62% 98%, 56% 29%, 79% 74%);
			//animation: auroraLight 3873ms -9869ms ease-in-out infinite alternate;
		}
		&:nth-child(22) {
			width: 994px;
			height: 914px;
			-webkit-clip-path: polygon(61% 5%, 53% 46%, 76% 75%);
			clip-path: polygon(61% 5%, 53% 46%, 76% 75%);
			animation: auroraLight 7800ms -4956ms ease-in-out infinite alternate;
		}
		&:nth-child(23) {
			width: 1007px;
			height: 1179px;
			-webkit-clip-path: polygon(15% 42%, 80% 8%, 22% 31%);
			clip-path: polygon(15% 42%, 80% 8%, 22% 31%);
			//animation: auroraLight 5936ms -2229ms ease-in-out infinite alternate;
		}
		&:nth-child(24) {
			width: 1155px;
			height: 913px;
			-webkit-clip-path: polygon(6% 49%, 24% 22%, 75% 26%);
			clip-path: polygon(6% 49%, 24% 22%, 75% 26%);
			animation: auroraLight 6445ms -1931ms ease-in-out infinite alternate;
		}
		&:nth-child(25) {
			width: 1167px;
			height: 957px;
			-webkit-clip-path: polygon(4% 9%, 71% 28%, 78% 12%);
			clip-path: polygon(4% 9%, 71% 28%, 78% 12%);
			//animation: auroraLight 3001ms -5206ms ease-in-out infinite alternate;
		}
		&:nth-child(26) {
			width: 869px;
			height: 931px;
			-webkit-clip-path: polygon(4% 87%, 1% 80%, 40% 30%);
			clip-path: polygon(4% 87%, 1% 80%, 40% 30%);
			animation: auroraLight 3098ms -9267ms ease-in-out infinite alternate;
		}
		&:nth-child(27) {
			width: 871px;
			height: 1033px;
			-webkit-clip-path: polygon(83% 6%, 20% 90%, 42% 39%);
			clip-path: polygon(83% 6%, 20% 90%, 42% 39%);
			//animation: auroraLight 5503ms -3985ms ease-in-out infinite alternate;
		}
		&:nth-child(28) {
			width: 1130px;
			height: 1190px;
			-webkit-clip-path: polygon(97% 18%, 10% 19%, 42% 75%);
			clip-path: polygon(97% 18%, 10% 19%, 42% 75%);
			animation: auroraLight 3352ms -3699ms ease-in-out infinite alternate;
		}
		&:nth-child(29) {
			width: 1256px;
			height: 987px;
			-webkit-clip-path: polygon(98% 9%, 22% 96%, 82% 51%);
			clip-path: polygon(98% 9%, 22% 96%, 82% 51%);
			//animation: auroraLight 3171ms -7103ms ease-in-out infinite alternate;
		}
		&:nth-child(30) {
			width: 1261px;
			height: 1225px;
			-webkit-clip-path: polygon(29% 36%, 51% 59%, 1% 71%);
			clip-path: polygon(29% 36%, 51% 59%, 1% 71%);
			animation: auroraLight 6299ms -8769ms ease-in-out infinite alternate;
		}
		&:nth-child(31) {
			width: 1239px;
			height: 1314px;
			-webkit-clip-path: polygon(32% 76%, 97% 38%, 85% 40%);
			clip-path: polygon(32% 76%, 97% 38%, 85% 40%);
			//animation: auroraLight 4109ms -91ms ease-in-out infinite alternate;
		}
		&:nth-child(32) {
			width: 889px;
			height: 1016px;
			-webkit-clip-path: polygon(41% 49%, 41% 82%, 75% 68%);
			clip-path: polygon(41% 49%, 41% 82%, 75% 68%);
			animation: auroraLight 5203ms -3751ms ease-in-out infinite alternate;
		}
		&:nth-child(33) {
			width: 1056px;
			height: 963px;
			-webkit-clip-path: polygon(52% 63%, 64% 73%, 88% 93%);
			clip-path: polygon(52% 63%, 64% 73%, 88% 93%);
			//animation: auroraLight 7058ms -5819ms ease-in-out infinite alternate;
		}
		&:nth-child(34) {
			width: 873px;
			height: 1191px;
			-webkit-clip-path: polygon(54% 8%, 34% 78%, 60% 90%);
			clip-path: polygon(54% 8%, 34% 78%, 60% 90%);
			animation: auroraLight 5138ms -8841ms ease-in-out infinite alternate;
		}
		&:nth-child(35) {
			width: 1072px;
			height: 1266px;
			-webkit-clip-path: polygon(54% 30%, 94% 75%, 59% 71%);
			clip-path: polygon(54% 30%, 94% 75%, 59% 71%);
			//animation: auroraLight 6443ms -3848ms ease-in-out infinite alternate;
		}
		&:nth-child(36) {
			width: 813px;
			height: 929px;
			-webkit-clip-path: polygon(35% 78%, 35% 59%, 54% 34%);
			clip-path: polygon(35% 78%, 35% 59%, 54% 34%);
			animation: auroraLight 4742ms -9755ms ease-in-out infinite alternate;
		}
		&:nth-child(37) {
			width: 969px;
			height: 1089px;
			-webkit-clip-path: polygon(99% 94%, 49% 70%, 47% 52%);
			clip-path: polygon(99% 94%, 49% 70%, 47% 52%);
			//animation: auroraLight 7442ms -8221ms ease-in-out infinite alternate;
		}
		&:nth-child(38) {
			width: 1208px;
			height: 1342px;
			-webkit-clip-path: polygon(16% 56%, 76% 2%, 61% 23%);
			clip-path: polygon(16% 56%, 76% 2%, 61% 23%);
			animation: auroraLight 3545ms -1601ms ease-in-out infinite alternate;
		}
		&:nth-child(39) {
			width: 924px;
			height: 930px;
			-webkit-clip-path: polygon(35% 11%, 11% 53%, 77% 30%);
			clip-path: polygon(35% 11%, 11% 53%, 77% 30%);
			//animation: auroraLight 3947ms -9419ms ease-in-out infinite alternate;
		}
		&:nth-child(40) {
			width: 1247px;
			height: 1204px;
			-webkit-clip-path: polygon(51% 60%, 67% 9%, 83% 97%);
			clip-path: polygon(51% 60%, 67% 9%, 83% 97%);
			animation: auroraLight 6859ms -9474ms ease-in-out infinite alternate;
		}
		&:nth-child(41) {
			width: 1132px;
			height: 1235px;
			-webkit-clip-path: polygon(11% 16%, 65% 8%, 37% 95%);
			clip-path: polygon(11% 16%, 65% 8%, 37% 95%);
			//animation: auroraLight 4693ms -9959ms ease-in-out infinite alternate;
		}
		&:nth-child(42) {
			width: 1201px;
			height: 1399px;
			-webkit-clip-path: polygon(72% 65%, 24% 84%, 38% 47%);
			clip-path: polygon(72% 65%, 24% 84%, 38% 47%);
			animation: auroraLight 7614ms -8613ms ease-in-out infinite alternate;
		}
		&:nth-child(43) {
			width: 1115px;
			height: 1295px;
			-webkit-clip-path: polygon(72% 36%, 4% 81%, 52% 95%);
			clip-path: polygon(72% 36%, 4% 81%, 52% 95%);
			//animation: auroraLight 5492ms -3533ms ease-in-out infinite alternate;
		}
		&:nth-child(44) {
			width: 1091px;
			height: 1266px;
			-webkit-clip-path: polygon(18% 81%, 88% 28%, 11% 45%);
			clip-path: polygon(18% 81%, 88% 28%, 11% 45%);
			animation: auroraLight 4124ms -9960ms ease-in-out infinite alternate;
		}
		&:nth-child(45) {
			width: 1166px;
			height: 1237px;
			-webkit-clip-path: polygon(78% 81%, 53% 57%, 73% 5%);
			clip-path: polygon(78% 81%, 53% 57%, 73% 5%);
			//animation: auroraLight 6289ms -8154ms ease-in-out infinite alternate;
		}
	}

	.crystalline-item.x {
		display: none;
	}
}

@include bp-from(xxl) {
	#crystalline {
    	transform: scale(1.2);
	}
}

@keyframes auroraLight {
	0% {
		background-position: 0% 0%;
	}
	100% {
		background-position: 0% 100%;
	}
}

/* aurora
---------------------------------------- */

#aurora-rays {
	animation-name: fadeInDown;
	@include animated(1s, 1s);
}

.daytime {
	#aurora-rays {
		mix-blend-mode: plus-lighter;
	}
}


$max-height: 350;
$speed: 6000ms;
$total-strands: 100;
$sml-strands: true;
$med-strands: true;
$big-strands: true;
$light-field: true;
$blur-amount: 1.1;
$motion: 100;
$max-brightness: 10;
$lf-opacity: 1;
$glow-nighttime: #ba5a8f; //#ff4fb1; //#c44fff;
$glow-daytime: #08ffd5; //#18c499; //#6cffe6; //#4fffe1;

// Ratio of strand sizes
$num-sml-strands: round(0.5 * $total-strands);
$num-med-strands: round(0.3 * $total-strands);
$num-big-strands: round(0.2 * $total-strands);

$sml-strand-width: 2;
$med-strand-width: 15;
$big-strand-width: 40;

$sml-min-opacity: 0;
$sml-max-opacity: .2;
$med-min-opacity: .1;
$med-max-opacity: .2;
$big-min-opacity: 0;
$big-max-opacity: .2;
$opacity-variation: 0.05;

$webkit: true;
$moz: true;
$ms: true;

@mixin vendorize($name, $argument) {
	@if $webkit { -webkit-#{$name}: #{$argument}; }
	@if $moz { -moz-#{$name}: #{$argument}; }
	#{$name}: #{$argument};
}

@mixin keyframes($name) {
	@if $webkit { @-webkit-keyframes #{$name} { @content; } }
	@if $moz { @-moz-keyframes #{$name} { @content; } }
	@keyframes #{$name} { @content; }
}

@mixin animation($animations...) {
	// Auto append vendor prefixes, accepts multiple list items:
	@include vendorize(animation, $animations);
}

$movement: $motion * 1px;

// Uniform spectrum for colors
$sml-degrees: round(calc(360 / $num-sml-strands));
$med-degrees: round(calc(360 / $num-med-strands));
$big-degrees: round(calc(360 / $num-big-strands));

.ray {
	border-radius: 20px;
	position:absolute;
		top: -100px;
	//will-change: transform;
	z-index: 0;

	// Star overlay to simulate sky perspective
	&:nth-child(1) {
		height: 100%;
		width: 100%;
	}

	// Offscreen glow "light-field"
	@if $light-field {
    	&:nth-child(2) {
			border-radius: 50%;
			height: 20px;
			margin: auto;
			opacity: $lf-opacity;
			position: absolute;
				left: 0;
				top: -70px;
			width: 100%;
			z-index: 9999;

			.daytime & {
				@include animation(light-field-day $speed * 7 ease-in-out infinite alternate);
			}

			.nighttime & {
				@include animation(light-field-night $speed * 7 ease-in-out infinite alternate);
			}
		}
	}

	// Smaller, brighter strands
	@if $sml-strands {
		@for $i from 3 through $num-sml-strands {
			&:nth-child(#{$i}) {
				height: random($max-height) + $movement;
				left: random(100) * 1%;
				margin-top: -$movement;
				$width: random($sml-strand-width) + $sml-strand-width;
				width: $width + px;

				// Spectrum
				$degrees: $sml-degrees * $i;
				background: adjust-hue(#ff0000, $degrees);

				// Blur
				@include vendorize(filter, blur(random($width) + 4 * $blur-amount + $width + px));

				@include animation(sml-fade ease-in-out random(1000) + $speed infinite,
				wiggle + random(3) ease-in-out random(1000) + $speed infinite);
			}
		}
	}

	// Medium, filler strands
	@if $med-strands {
		@for $i from $num-sml-strands + 1 through $num-sml-strands + $num-med-strands  {
			&:nth-child(#{$i}) {
				height: random($max-height) + $movement;
				left:random(100) * 1%;
				$width: random($med-strand-width) + $med-strand-width;
				width: $width + px;

				// Spectrum
				$degrees: $med-degrees * $i;
				background: adjust-hue(#ff0000, $degrees);

				// Blur
				@include vendorize(filter, blur(random($width) + $blur-amount * .7 * $width + px));

				@include animation(med-fade ease-in-out random(1000) + $speed infinite,
				wiggle + random(3) ease-in-out random(10000) + $speed infinite);
			}
		}
	}

	// Larger, nebula-like strands
	@if $big-strands {
		@for $i from $num-sml-strands + $num-med-strands + 1  through $total-strands {
			&:nth-child(#{$i}) {
				height: random($max-height) + $movement;
				left:random(100) * 1%;
				$width: random($big-strand-width) + $big-strand-width;
				width: $width + px;

				// Spectrum
				$degrees: $big-degrees * $i;
				background: adjust-hue(#ff0000, $degrees);

				// Blur
				@include vendorize(filter, blur(random($width) + $blur-amount * .5 * $width + px));

				// Fade
				@include animation(big-fade ease-in-out random(1000) + $speed infinite,
				wiggle + random(3) ease-in-out random(10000) + $speed infinite);
			}
		}
	}

	transform: translate3d(0, 0, 0);
}

@include keyframes(sml-fade) {
	0% { opacity: $sml-max-opacity - $opacity-variation; }
	50% { opacity: $sml-min-opacity; }
	100% { opacity: $sml-max-opacity; }
};

@include keyframes(med-fade) {
	0% { opacity :$med-max-opacity; }
	50% { opacity :$med-min-opacity; }
	100% { opacity :$med-max-opacity - $opacity-variation; }
};

@include keyframes(big-fade) {
	0% { opacity: $big-max-opacity; }
	50% { opacity: $big-min-opacity; }
	100% { opacity: $big-max-opacity - $opacity-variation; }
};

$mx1: random($motion) *  1px;
$mx2: random($motion) * -1px;
$mx3: random($motion) *  1px;
$mx4: random($motion) * -1px;

$my1: random($motion) *  1px;
$my2: random($motion) * -2px;
$my3: random($motion) *  2px;
$my4: random($motion) * -1px;

@include keyframes(wiggle0) {
	0% { transform: translate3d($mx1, -$movement, 0); }
	50% { transform: translate3d(-$my2, -$my2, 0) scaleX(1.5); }
	100% { transform: translate3d($mx1, -$movement, 0); }
};

@include keyframes(wiggle1) {
	0% { transform: translate3d(-$mx2, -$movement, 0); }
	50% { transform: translate3d($my3,  $my3, 0) scaleX(2); }
	100% { transform: translate3d(-$mx2, -$movement, 0); }
};

@include keyframes(wiggle2) {
	0% { transform: translate3d(-$mx3, -$movement, 0); }
	50% { transform: translate3d($my4,  $my4, 0) scaleX(2.5); }
	100% { transform: translate3d(-$mx3, -$movement, my3, 0); }
};

@include keyframes(wiggle3) {
	0% { transform: translate3d($mx4, -$movement, 0); }
	50% { transform: translate3d(-$my1, $my1, 0) scaleX(3); }
	100% { transform: translate3d($mx4, -$movement, 0); }
};

@include keyframes(light-field-night) {
	from { box-shadow: 0 0 (0.8 * $max-height + px) (0.3 * $max-height + px) $glow-nighttime; }
	to { box-shadow: 0 0 (0.6 * $max-height + px) (0.1 * $max-height + px) $glow-nighttime; }
};

@include keyframes(light-field-day) {
  from { box-shadow: 0 0 (0.8 * $max-height + px) (0.3 * $max-height + px) $glow-daytime; }
    to { box-shadow: 0 0 (0.6 * $max-height + px) (0.1 * $max-height + px) $glow-daytime; }
};
