/* type
---------------------------------------- */

@mixin font-robotolight {
	font-family: 'robotolight', sans-serif;
}

@mixin font-robotobold {
	font-family: 'robotobold', sans-serif;
	text-transform: uppercase;
}

@mixin font-barlowregular {
	font-family: 'barlowregular', sans-serif;
	text-transform: uppercase;
}

@mixin font-barlowbold {
	font-family: 'barlowbold', sans-serif;
	text-transform: uppercase;
}

@mixin font-barlowextrabold {
	font-family: 'barlowextrabold', sans-serif;
	text-transform: uppercase;
}


/* background-size
---------------------------------------- */

@mixin background-size($size){
    -webkit-background-size: $size;
       -moz-background-size: $size;
         -o-background-size: $size;
            background-size: $size;
}

/* typography
---------------------------------------- */

@mixin title-background {
	background: rgba(#eee, 0.75);
	box-decoration-break: clone;
	-webkit-box-decoration-break: clone;
	box-shadow: $unit * 0.5 0 0 rgba(#eee, 0.75), -($unit * 0.5) 0 0 rgba(#eee, 0.75);
	display: inline;
}

/* lists reset
---------------------------------------- */

@mixin plain-list {
	list-style: none;
	list-style-position: inside;
	margin: 0;
	padding: 0;
}
