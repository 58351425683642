/* css animations
---------------------------------------------------------------------------- */

@mixin animated($animation-duration: 0.75s, $animation-delay: 0) {
    animation-duration: $animation-duration;
    animation-fill-mode: both;
    animation-delay: $animation-delay;
    //animation-timing-function: ease-out;
}

@mixin animated-infinite {
    animation-iteration-count: infinite;
    animation-duration: 1.5s;
}

// used in _page-blog, _start-graphic, _left-dark
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

// used in _start-graphic
@keyframes fadeInDown {
    from { opacity: 0; transform: translate3d(0, -100px, 0); }
    to { opacity: 1; transform: none; }
}

@keyframes fadeInUp {
    from { opacity: 0; transform: translate3d(0, 50px, 0); }
    to { opacity: 1; transform: none; }
}

@keyframes fadeInUp2 {
    from { opacity: 0; transform: translate3d(0, 100%, 0); }
    to { opacity: 1; transform: none; }
}

// used in _page-blog, _left-dark
@keyframes fadeInLeft {
    from { opacity: 0; transform: translate3d(-100%, 0, 0); }
    to { opacity: 1; transform: none; }
}

// used in _page-blog
@keyframes fadeInLeft2 {
    0% { opacity: 0; transform: translate3d(-100%, 0, 0); }
    95% { opacity: 1; }
    100% { transform: none; }
}

@keyframes fadeOutDown {
    from { opacity: 1; transform: none; }
    to { opacity: 0; transform: translate3d(0, 100%, 0); }
}

@keyframes fadeOutUp {
    from { opacity: 1; transform: none; }
    to { opacity: 0; transform: translate3d(0, -100%, 0); }
}

// used in _start-graphic
@keyframes slideInUp {
    from { transform: translate3d(0, 150px, 0); visibility: visible; }
    to { transform: translate3d(0, 0, 0); visibility: visible;  }
}

@keyframes slideInDown {
    from { transform: translate3d(0, -100px, 0); visibility: visible; }
    to { transform: translate3d(0, 0, 0); visibility: visible; }
}

// used in _start-graphic
@keyframes slideInRight {
    0% { transform: translate3d(100%, 0, 0); }
	60% { transform: translate3d(-4%, 0, 0); }
    100% { transform: translate3d(0, 0, 0); }
}

//used in _left-dark
@keyframes slideInLeft {
    0% { transform: translate3d(-100%, 0, 0); }
	60% { transform: translate3d(4%, 0, 0); }
    100% { transform: translate3d(0, 0, 0); }
}

// used in _start-graphic
@keyframes slideInUpBounce {
    0% { transform: translate3d(0, 200px, 0); opacity: 0; }
    90% { transform: translate3d(0, -15px, 0); opacity: 1; }
    100% { transform: translate3d(0, 0, 0); opacity: 1; }
}

@keyframes fallInDown {
    0% { transform: translate3d(0, -30px, 0); visibility: hidden; }
    95% { transform: translate3d(0, 5px, 0); visibility: visible; }
    100% { transform: translate3d(0, 0, 0); visibility: visible; }
}

@mixin module-animation-transparency {
    opacity: 0;

    &.make-it-fancy,
    &.fancy-finish {
        opacity: 1;
    }
}

// used in _start-graphic
@keyframes grow-right {
	0% { width: 0; }
	100% { width: 50vw; }
}
