.system-navigation {
	ul {
		display: flex;
			flex-direction: row;
			justify-content: center;
		margin: $unit 0 0;
	}

	li {
		@include plain-list;
	}

	a {
		@include font-barlowbold;
		font-size: 0.875em;
		padding: 0 (calc($unit / 3));
		text-transform: uppercase;
	}
}
