
/*
** clearfix (via bootstrap)
*/

@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

.clearfix {
  @include clearfix;
}

/*
** Responsive Videos
*/

.responsive-video-wrapper {
	margin: 0 auto;
	width: 100%;
	max-width: 1400px;
}

.responsive-video {
	height: 0;
	overflow: hidden;
	padding-bottom: 56.25%; /* 16:9 ratio*/
	padding-top: 0px;
	position: relative;
}

.responsive-video iframe {
	height: 100%;
	position: absolute;
		left: 0;
		top: 0;
	width: 100%;
}
