/* colours
---------------------------------------- */

/* monochromes */

$obsidian:			#1A191F;
$obsidian2:			lighten($obsidian, 1.5%);
$obsidian3:			lighten($obsidian, 3%);
$obsidian4:			lighten($obsidian, 10%);
$obsidian5:			lighten($obsidian, 20%);
$obsidian6:			lighten($obsidian, 30%);
$obsidian7:			lighten($obsidian, 40%);

$clay:				#ddd;
$clay-dark:			darken($clay, 10%);
$clay-darker:		darken($clay, 30%);

$white:             #fefefe;
$quartz:			#f1f1f1;
$quartz-light:		lighten($quartz, 3%);
$quartz-dark:		darken($quartz, 5%);

/* turquoise grey */

$turquoise-grey-dark: #2B4F54;
$turquoise-grey-medium: #3F6064;
$turquoise-grey-light: #82D3CB;

/* turquoise */

$turquoise-dark: #1E5E65;
$turquoise-medium: #207277;
$turquoise-light: #389296;

/* purple */

$purple-dark: #2C1B2D;
$purple-medium: #59415E;
$purple-light: #73748A;

/* nighttime colours */

$night-red: #d46b70;
$night-purple: #315384;
$night-blue: #413f64;

/* grid helper
---------------------------------------- */

$unit: 30px;
$grid-gutter-width: $unit;


/* breakpoints
---------------------------------------- */

$screen-xxxs-max: 	  320px; //to-xxs
$screen-xxs-min:	  321px; //from-xxs
$screen-xxs-max:      479px; //to-xs
$screen-xs-min:       480px; //from-xs
$screen-xs-max:       599px; //to-s
$screen-s-min:		  600px; //from-s
$screen-s-max:		  767px; //to-sm
$screen-sm-min:       768px; //from-sm
$screen-sm-max:       991px; //to-md
$screen-md-min:       992px; //from-md
$screen-md-max:       1299px; //to-lg
$screen-lg-min:       1300px; //from-lg
$screen-lg-max:       1699px; //to-xl
$screen-xl-min:       1700px; //from-xl
$screen-xl-max:		  1999px; //to-xxl
$screen-xxl-min:	  2000px; //from-xxl


/* xl++ grid extension for .container
---------------------------------------- */

$container-xl-desktop:  ( $screen-xl-min - $grid-gutter-width) !default;
$container-xl:          $container-xl-desktop !default;
$container-xxl-desktop: ($screen-xxl-min - $grid-gutter-width) !default;
$container-xxl:         $container-xxl-desktop !default;


.container {
  @media (min-width: $screen-xl-min) {
    width: $container-xl;
  }
  @media (min-width: $screen-xxl-min) {
    width: $container-xxl;
  }
}
