/* logo
---------------------------------------- */

/* logo-container */

.logo-container {
	display: flex;
		align-items: center;
		justify-content: center;
	height: 270px;
	margin-left: auto;
	margin-right: auto;
	margin-top: -100px;
	padding: 0;
	text-align: center;
	width: auto;

	@include bp-to(s) {
		height: 250px;
	}

	@include bp-from-to(s-sm) {
		height: 270px;
		//margin-bottom: 70px;
	}

	@include bp-from-to(sm-md) {
		height: 370px;
		//margin-bottom: 70px;
		width: 370px;
	}

	@include bp-from(md) {
		height: 270px;
		//margin-bottom: 100px;
	}
}

/* logo-wrapper */

.logo-wrapper {
	position: relative;
	width: auto;
}

/* logo image */

.logo {
	border: none;
	display: inline-block;
	margin: 0 auto;
	mix-blend-mode: overlay;
	opacity: 0;
	position: absolute;
		left: 0;
		top: 0;
	text-decoration: none;

	filter: alpha(opacity=0);
	transition: all 0.3s ease;
	animation: reveal-banner 1.5s 0.5s ease-in-out;
	animation-fill-mode: forwards;

	.number {
		path {
		    fill: #ccc;
		}
	}

	&:hover,
	&:focus {
		mix-blend-mode: hard-light;
		transform: rotate(-3deg);

		.circle {
			opacity: 0.65;
		}

		.number {
			path {
				fill: #fff;
			}
		}
	}
}

.logo {
	svg {
		height: auto;
		width: 100%;
	}
}

@keyframes reveal-banner {
	0% { opacity: 0; }
  	100% { opacity: 1; }
}

/* logo size
---------------------------------------- */

.moon-spinner,
.rocket-spinner {
	@include bp-to(s) {
		height: 120px;
		width: 120px;
	}

	@include bp-from-to(s-sm) {
		height: 150px;
		width: 150px;
	}

	@include bp-from-to(sm-md) {
		height: 200px;
		width: 200px;
	}

	@include bp-from(md) {
		height: 250px;
		width: 250px;
	}
}

/* logo animation
---------------------------------------- */

@keyframes rotate360deg {
	to { transform: rotate(360deg); }
}

/* moon */

.moon-spinner {
	animation: rotate360deg 12s linear infinite;
	border-radius: 50%;
	position: absolute;
		left: 0%;
		top: 0px;
}

.moon {
	background: #3F6064;
	border-radius: 50%;
	height: 10px;
	width: 10px;

	@include bp-to(md) {
		margin-top: 15px;
	}

	@include bp-from(md) {
		margin-top: 30px;
	}

	.nighttime & {
		background: #090e0e;
	}
}

/* rocket */

.rocket-spinner {
	animation: rotate360deg 8s linear infinite;
	border-radius: 50%;
	position: relative;
}

.rocket {
	background-color: #705277;
	border-bottom-right-radius: 100%;
	border-top-right-radius: 100%;
	box-sizing: content-box;
	display: block;
	position: absolute;
	transform: rotate(-36deg);

	@include bp-to(md) {
		border-left: 1px solid #5B4560;
		border-right: 1px solid #59415E;
		height: 8px;
		margin-top: -15px;
		width: 20px;
	}

	@include bp-from(md) {
		border-left: 3px solid #5B4560;
		border-right: 3px solid #59415E;
		height: 16px;
		margin-top: -30px;
		width: 40px;
	}

	.nighttime & {
		background-color: #59405f;
		border-left-color: #312533;
	}

	&:before,
	&:after {
		background-color: #59415E;
		box-sizing: content-box;
		content: '';
		display: block;
		height: 50%;
		position: absolute;
		top: 50%;
		width: 51%;

		.nighttime & {
			background-color: #312533;
		}
	}

	&:before {
		right: 0;
		border-bottom-right-radius: 100%;
	}

	&:after {
		@include bp-to(md) {
			left: -1px;
			border-left: 1px solid #444244;
		}

		@include bp-from(md) {
			left: -3px;
			border-left: 3px solid #444244;
		}

		.nighttime & {
			border-left-color: #231a23;
		}
	}

	i.fin-top,
	i.fin-bottom {
		background-color: $purple-dark;
		display: block;
		position: absolute;

		@include bp-to(md) {
			border-left: 1px solid $clay;
			height: 5px;
			left: -2px;
			width: 5px;
		}

		@include bp-from(md) {
			border-left: 3px solid $clay;
			height: 10px;
			left: -4px;
			width: 10px;
		}

		&:before {
			background-color: #705277;
			bottom: 0;
			content: '';
			height: 100%;
			position: absolute;

			@include bp-to(md) {
				left: 2px;
				width: 5px;
			}

			@include bp-from(md) {
				left: 5px;
				width: 10px;
			}

			.nighttime & {
				background-color: #59405f;
			}
		}
	}

	i.fin-top {
		transform: skew(35deg, 0deg);

		@include bp-to(md) {
			top: -5px;
		}

		@include bp-from(md) {
			top: -10px;
		}

		&:before {
			transform: skew(40deg, 0deg);
		}
	}

	i.fin-bottom {
		transform: skew(-35deg, 0deg);

		@include bp-to(md) {
			bottom: -5px;
		}

		@include bp-from(md) {
			bottom: -10px;
		}

		&:before {
			transform: skew(-40deg, 0deg);
		}
	}

	i.faya {
		display: block;
		height: 100%;
		overflow: hidden;
		position: absolute;

		@include bp-to(md) {
			left: -16px;
			width: 15px;
		}

		@include bp-from(md) {
			left: -33px;
			width: 30px;
		}

		&:before {
			border-radius: 100%;
			background-color: $turquoise-grey-light;
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			animation: vrouming 200ms infinite;

			@include bp-to(md) {
				height: 8px;
				width: 110px;
			}

			@include bp-from(md) {
				height: 16px;
				width: 220px;
			}

			.nighttime & {
				background-color: #9678c7;
			}
		}

		&:after {
			background-color: $quartz;
			border-radius: 100%;
			content: '';
			display: block;
			position: absolute;
			animation: vrouming 200ms infinite;

			@include bp-to(md) {
				height: 5px;
				left: 8px;
				top: 1px;
				width: 110px;
			}

			@include bp-from(md) {
				height: 10px;
				left: 16px;
				top: 3px;
				width: 220px;
			}
		}
	}

	i.wastes {
		position: absolute;

		@include bp-to(md) {
			height: 100%;
			left: -27px;
			top: -5px;
			width: 25px;
		}

		@include bp-from(md) {
			height: 200%;
			left: -53px;
			top: -10px;
			width: 50px;
		}

		i {
			background-color: rgba(255, 255, 255, 0.5);
			height: 1px;
			position: absolute;
			right: 0;
			width: 3px;

			&:nth-child(1) {
				animation: wasting 500ms infinite 100ms;

				@include bp-to(md) {
					top: 11px;
				}

				@include bp-from(md) {
					top: 15px;
				}
			}

			&:nth-child(2) {
				animation: wasting2 500ms infinite 200ms;

				@include bp-to(md) {
					top: 8px;
				}

				@include bp-from(md) {
					top: 17px;
				}
			}

			&:nth-child(3) {
				animation: wasting 500ms infinite 300ms;

				@include bp-to(md) {
					display: none;
				}

				@include bp-from(md) {
					top: 19px;
				}
			}

			&:nth-child(4) {
				animation: wasting2 500ms infinite 400ms;

				@include bp-to(md) {
					top: 10px;
				}

				@include bp-from(md) {
					top: 21px;
				}
			}

			&:nth-child(5) {
				animation: wasting 500ms infinite 100ms;

				@include bp-to(md) {
					display: none;
				}

				@include bp-from(md) {
					top: 23px;
				}
			}
		}
	}
}

@keyframes vrouming {
  0% { transform: translate3d(0px, 0, 0); }
  50% { transform: translate3d(5px, 0, 0); }
  100% { transform: translate3d(0px, 0, 0); } }

@keyframes wasting {
  0% { transform: translate3d(0px, 0, 0); }
  100% { transform: translate3d(-40px, -5px, 0); } }

@keyframes wasting2 {
  0% { transform: translate3d(0px, 0, 0); }
  100% { transform: translate3d(-40px, 5px, 0); } }
