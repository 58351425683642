/* navigations
---------------------------------------- */

.start-menu {
	width: 100%;
}

.menu-list {
	@include plain-list;
}

.menu {
	padding: $unit ($unit * 0.5);
	width: 100%;

	a {
		@include font-barlowbold;

		display: block;
		line-height: 1;
		white-space: nowrap;

		@include bp-to(md) {
			font-size: 18vw;
		}

		@include bp-from-to(md-lg) {
			font-size: 8vw;
		}

		@include bp-from(lg) {
			font-size: 120px;
		}

		@include bp-from(xl) {
			font-size: 150px;
		}

		@include bp-from(xxl) {
			font-size: 180px;
		}
	}
}
